import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBFxHO6JgLCb4b7eRrfudkqrwqvfMxNkeA",
    authDomain: "bep-selfie-upload.firebaseapp.com",
    projectId: "bep-selfie-upload",
    storageBucket: "bep-selfie-upload.appspot.com",
    messagingSenderId: "567637566007",
    appId: "1:567637566007:web:96934767732dca240815b2",
    measurementId: "G-4VKJDK6W9E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };